<footer class="relative bg-grey-600">
  <div class="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
    <span class="absolute inset-x-0 top-0 h-1 bg-gradient-to-r from-grey-500 via-blue-500 to-grey-500"></span>
    <nav aria-labelledby="footer-navigation">
      <div class="text-white">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:divide-x divide-grey-300">
          <div class="self-center w-full flex flex-row justify-center">
            <img class="h-36" alt="Direct Tyre Management Logo" src="../../../../../assets/images/dtm-logo-full-dark-bg.png" />
          </div>
          <div class="self-center px-6 hidden md:block">
            <!--            <p class="font-bold text-2xl">Support</p>-->
            <!--            <nav class="mt-2 flex flex-col space-y-2 text-sm text-gray-300">-->
            <!--              <a class="hover:opacity-75" href=""> Contact </a>-->
            <!--              <a class="hover:opacity-75" href=""> Another Link </a>-->
            <!--            </nav>-->
          </div>
          <div class="self-center px-6 hidden md:block">
            <!--<p class="font-bold text-2xl">Other Links</p>-->
            <!--<nav class="mt-2 flex flex-col space-y-2 text-sm text-gray-300">-->
            <!--  <a class="hover:opacity-75" href="">One More Link</a>-->
            <!--  <a class="hover:opacity-75" href="">Some Other Link</a>-->
            <!--</nav>-->
          </div>
        </div>
        <div>
          <div class="p-4 text-center text-gray-300">
            <div>
              <a
                href="https://directtyre.com/privacy-policy"
                target="_blank"
                class="text-gray-300 transition hover:text-blue-500 hover:underline">
                Privacy Policy
              </a>
              <span class="mx-3 text-blue-500">|</span>
              <a
                href="https://www.directtyre.com/cookie-policy/"
                target="_blank"
                class="text-gray-300 transition hover:text-blue-500 hover:underline">
                Cookie Policy
              </a>
              <span class="mx-3 text-blue-500">|</span>
              <a (click)="openSupport()" class="cursor-pointer text-gray-300 transition hover:text-blue-500 hover:underline">Support</a>
              <span class="mx-3 text-blue-500">|</span>
              <a
                href="https://www.directtyre.com"
                target="_blank"
                rel="noopener noreferrer"
                class="text-gray-300 transition hover:text-blue-500 hover:underline">
                directtyre.com
              </a>
            </div>
            <div class="my-2 text-gray-500">© Direct Tyre Management Limited {{ currentDate | date: 'yyyy' }}</div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</footer>
